define("linced-enrolments/components/chart-donut", ["exports", "@linc-technologies/linced-common/components/chart-donut"], function (_exports, _chartDonut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _chartDonut.default;
    }
  });
});