define("linced-enrolments/ember-videojs/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/videojs-base.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/videojs-base.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/videojs-player.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/videojs-player.hbs should pass TemplateLint.\n\naddon/templates/components/videojs-player.hbs\n  24:4  error  Do not use an <a> element with the `href` attribute inside an <video> element with the `controls` attribute  no-nested-interactive\n');
  });
});