define("linced-enrolments/models/people-schema/field-option", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // value is what actually gets stored in the database if this option is selected.
    value: (0, _model.attr)('string'),
    // label is a human-friendly label for this option.
    label: (0, _model.attr)('string'),
    // valueInt is used for storing numeric values in the database
    valueInt: (0, _model.attr)('number', {
      defaultValue: 0
    })
  });
  _exports.default = _default;
});