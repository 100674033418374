define("linced-enrolments/models/school-options/heading", ["exports", "@linc-technologies/linced-common/models/school-options/heading"], function (_exports, _heading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _heading.default;
    }
  });
});