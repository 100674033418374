define("linced-enrolments/routes/apply-complete", ["exports", "linced-enrolments/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(_ref) {
      var country_code = _ref.country_code,
        school_code = _ref.school_code;
      return this.store.query('enrolments/schema', {
        countryCode: country_code,
        schoolCode: school_code
      });
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      // Extract schema object or redirect to not found.
      if (Ember.isEmpty(model)) {
        this.transitionTo('school-not-found');
      } else {
        controller.set('schema', model.get('firstObject'));
      }
    },
    // Routing actions
    actions: {
      transitionToNewApplication: function transitionToNewApplication(schema) {
        this.transitionTo('apply', schema.countryCode, schema.schoolCode);
      }
    }
  });
  _exports.default = _default;
});