define("linced-enrolments/serializers/people-schema/field", ["exports", "linced-enrolments/serializers/people-schema/base", "@linc-technologies/linced-common/enums/data-type", "@linc-technologies/linced-common/enums/input-type"], function (_exports, _base, _dataType, _inputType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    serializeIntoHash: function serializeIntoHash(hash) {
      this._super.apply(this, arguments);
      var dataType = 0;
      switch (hash.field.inputType) {
        case _inputType.default.CHECKBOX:
        case _inputType.default.MULTI_SELECT:
          dataType = _dataType.default.LIST;
          break;
        case _inputType.default.NUMBER:
          // Already set via the UI.
          dataType = hash.field.dataType;
          break;
        case _inputType.default.SELECT:
          dataType = hash.field.dataType || _dataType.default.STRING;
          break;
        case _inputType.default.SWITCH:
          dataType = _dataType.default.BOOLEAN;
          break;
        default:
          dataType = _dataType.default.STRING;
          break;
      }
      hash.field.dataType = dataType;
    }
  });
  _exports.default = _default;
});