define("linced-enrolments/adapters/people-schema/field", ["exports", "linced-enrolments/adapters/people-schema/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    modelNamePlural: 'fields',
    queryRecord: function queryRecord(store, model, query) {
      if (Ember.getWithDefault(query, 'jurisdictionalId', false)) {
        var url = this.buildURL(model.modelName, 'jurisdictionalId', null, 'findRecord');
        return this.ajax(url, 'GET', {});
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});