define("linced-enrolments/models/media/rendition", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // size is a string representing the size, e.g. "thumb" or "100x100".
    size: (0, _model.attr)('string'),
    // storageKey is the generated file name under which this rendition is
    // actually stored.
    storageKey: (0, _model.attr)('string'),
    // width is the width of this rendition in pixels.
    width: (0, _model.attr)('number'),
    // height is the height of this rendition in pixels.
    height: (0, _model.attr)('number'),
    // fileSize is the size of this rendition in bytes.
    fileSize: (0, _model.attr)('number'),
    // mimeType optionally overrides the MediaMeta records' MimeType field,
    // i.e. for thumbnail images.
    mimeType: (0, _model.attr)('string')
  });
  _exports.default = _default;
});