define("linced-enrolments/components/post-template/edit-dialog", ["exports", "@linc-technologies/linced-common/components/post-template/edit-dialog"], function (_exports, _editDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _editDialog.default;
    }
  });
});