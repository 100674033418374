define("linced-enrolments/serializers/milestones/progression", ["exports", "@linc-technologies/linced-common/serializers/milestones/progression"], function (_exports, _progression) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _progression.default;
    }
  });
});