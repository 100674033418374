define("linced-enrolments/models/assessments/gradeschedule-selectvalue", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    value: (0, _model.attr)(),
    label: (0, _model.attr)(),
    description: (0, _model.attr)(),
    isAddedOption: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});