define("linced-enrolments/components/chart-resizer-sentinel", ["exports", "@linc-technologies/linced-common/components/chart-resizer-sentinel"], function (_exports, _chartResizerSentinel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _chartResizerSentinel.default;
    }
  });
});