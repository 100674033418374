define("linced-enrolments/models/goals/badge-recipient", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // The property by which the recipient of a badge is identified.
    // This value should be an IRI mapped in the present context.
    // For example, email maps to http://schema.org/email and indicates that the identity of the IdentityObject will represent a value of a Profile’s email property.
    // "email"
    type: (0, _model.attr)('string'),
    // Plaintext identity value
    // "alice@example.org"
    identity: (0, _model.attr)('string')
  });
  _exports.default = _default;
});