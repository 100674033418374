define("linced-enrolments/models/people/options", ["exports", "ember-data-model-fragments", "ember-data-model-fragments/attributes", "@ember-data/model", "@linc-technologies/linced-common/enums/person-option-attendance-view-mode", "@linc-technologies/linced-common/enums/person-option-notification-preference", "@linc-technologies/linced-common/enums/person-option-search-mode"], function (_exports, _emberDataModelFragments, _attributes, _model, _personOptionAttendanceViewMode, _personOptionNotificationPreference, _personOptionSearchMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extends From
  // Ember Imports
  // Internal Imports
  var _default = _emberDataModelFragments.default.Fragment.extend({
    notificationPreference: (0, _model.attr)('number', {
      defaultValue: _personOptionNotificationPreference.default.DEFAULT
    }),
    searchMode: (0, _model.attr)('number', {
      defaultValue: _personOptionSearchMode.default.GRID
    }),
    myGroupsMode: (0, _model.attr)('number', {
      defaultValue: _personOptionSearchMode.default.GRID
    }),
    sharedGroupsMode: (0, _model.attr)('number', {
      defaultValue: _personOptionSearchMode.default.GRID
    }),
    autoGroupsMode: (0, _model.attr)('number', {
      defaultValue: _personOptionSearchMode.default.GRID
    }),
    classGroupsMode: (0, _model.attr)('number', {
      defaultValue: _personOptionSearchMode.default.GRID
    }),
    groupMode: (0, _model.attr)('number', {
      defaultValue: _personOptionSearchMode.default.GRID
    }),
    attendanceViewMode: (0, _model.attr)('number', {
      defaultValue: _personOptionAttendanceViewMode.default.PERIOD
    }),
    enrolmentApplicationsCustomFields: (0, _attributes.array)('string')
  });
  _exports.default = _default;
});