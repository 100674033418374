define("linced-enrolments/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XHHgXohj",
    "block": "{\"symbols\":[],\"statements\":[[5,\"log-card\",[],[[\"@title\",\"@warn\"],[\"Something went wrong\",true]],{\"statements\":[[0,\"\\n  Something went wrong while trying to load the requested page.\"],[7,\"br\",true],[8],[9],[0,\"\\n  Please\"],[5,\"paper-button\",[],[[\"@primary\",\"@onClick\"],[true,[28,\"action\",[[23,0,[]],\"reload\"],null]]],{\"statements\":[[0,\"click here\"]],\"parameters\":[]}],[0,\"to try again.\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-enrolments/templates/error.hbs"
    }
  });
  _exports.default = _default;
});