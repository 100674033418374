define("linced-enrolments/models/group-member", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    personId: (0, _model.attr)('string'),
    status: (0, _model.attr)('number'),
    person: Ember.computed('personId', function () {
      return this.store.peekRecord('person', this.personId);
    }),
    fullName: Ember.computed.alias('person.fullName')
  });
  _exports.default = _default;
});