define("linced-enrolments/helpers/enum-value", ["exports", "@linc-technologies/linced-common/helpers/enum-value"], function (_exports, _enumValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _enumValue.default;
    }
  });
  Object.defineProperty(_exports, "enumValue", {
    enumerable: true,
    get: function get() {
      return _enumValue.enumValue;
    }
  });
});