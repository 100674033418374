define("linced-enrolments/routes/apply", ["exports", "linced-enrolments/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    queryParams: {
      previewSchemaId: {
        as: 'preview'
      }
    },
    // Route state
    // Stores the countryCode as a param in order to transition to a country
    // selection.
    countryCode: '',
    // needs to be pushed down due to ember-paper-stepper pushes state into the
    // controller.
    currentStep: 0,
    //// Model State
    // schema contains the enrolment form field schema
    schema: null,
    // applicationRecord contains the form field data to apply to enrol.
    applicationRecord: null,
    model: function model(_ref) {
      var country_code = _ref.country_code,
        school_code = _ref.school_code;
      this.set('countryCode', country_code);
      return this.store.query('enrolments/schema', {
        countryCode: country_code,
        schoolCode: school_code
      });
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      // Extract schema object or redirect to not found.
      if (Ember.isEmpty(model)) {
        this.transitionTo('school-not-found');
        return;
      }

      // Bind in the enrolment schema
      this.set('schema', model.get('firstObject'));

      // Create a new application to bind the schema data into.
      var props = this.schema.getProperties('enrolmentYear', 'countryCode', 'schoolCode', 'campusCode', 'schoolId', 'campusId', 'jurisdiction');
      var applicationRecord = this.store.createRecord('enrolments/application', props);
      applicationRecord.initFields();

      // Initialise contact fragments
      if (!Ember.isEmpty(this.schema.primaryContacts)) {
        for (var i = 0; i < this.schema.get('primaryContacts.count'); i++) {
          applicationRecord.contacts.createFragment({
            type: 1 // Primary contact
          });
        }
      }

      if (!Ember.isEmpty(this.schema.emergencyContacts)) {
        for (var _i = 0; _i < this.schema.get('emergencyContacts.count'); _i++) {
          applicationRecord.contacts.createFragment({
            type: 4 // Emergency contact
          });
        }
      }

      if (!Ember.isEmpty(this.schema.medicalContacts)) {
        for (var _i2 = 0; _i2 < this.schema.get('medicalContacts.count'); _i2++) {
          applicationRecord.medicalContacts.createFragment({});
        }
      }
      if (!Ember.isEmpty(this.schema.medicalConditions)) {
        for (var _i3 = 0; _i3 < this.schema.get('medicalConditions.count'); _i3++) {
          applicationRecord.medicalConditions.createFragment({});
        }
      }
      applicationRecord.contacts.forEach(function (contact) {
        contact.initFields();
      });
      applicationRecord.medicalContacts.forEach(function (contact) {
        contact.initFields();
      });
      applicationRecord.medicalConditions.forEach(function (condition) {
        condition.initFields();
      });

      // Bind applicationRecord into state
      this.set('applicationRecord', applicationRecord);

      // currently ember-paper-stepper is pushing its state into the controller
      // and needs to be manually overridden.
      controller.set('currentStep', this.currentStep);
      controller.setProperties(this.getProperties('countryCode', 'currentStep', 'schema', 'applicationRecord'));
    },
    // Routing actions
    actions: {
      transitionToComplete: function transitionToComplete(schema) {
        this.transitionTo('apply-complete', schema.countryCode, schema.schoolCode);
      }
    }
  });
  _exports.default = _default;
});