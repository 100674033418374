define("linced-enrolments/models/bookings/reservation", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    // createTime is when the resource was created in seconds from the epoch.
    createTime: (0, _model.attr)('number'),
    // personId is the id of the person the reservation relates to.
    personId: (0, _model.attr)('string')
  });
  _exports.default = _default;
});