define("linced-enrolments/models/enrolments/section", ["exports", "ember-data-model-fragments", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _emberDataModelFragments, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // index is the order in which the section should be sorted/displayed.
    index: (0, _model.attr)('number'),
    // title is the the name given to the section, for example, 'Basic Details'
    title: (0, _model.attr)('string'),
    // description provides a place for schools to explain why they need the following sections information.
    description: (0, _model.attr)('string'),
    // fields contains all the form inputs for a given section.
    fields: (0, _attributes.fragmentArray)('enrolments/field'),
    // fieldIds extracts a comma-delimited list of the IDs from the fields within this section.
    fieldIds: Ember.computed('fields.@each.fieldId', function () {
      return this.fields.mapBy('fieldId').join(',');
    }),
    // count determines the number of repetitions for contact fields, if this is a contact section.
    count: (0, _model.attr)('number'),
    // includeBillPayer is used by contact sections to determine whether to show the bill payer flag.
    includeBillPayer: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});