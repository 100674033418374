define("linced-enrolments/templates/apply-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8jbgtppX",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\",\"card\",\"title\",\"text\"],\"statements\":[[5,\"paper-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,4,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,5,[\"media\"]],[],[[\"@alt\",\"@size\",\"@src\",\"@title\"],[[28,\"concat\",[[24,[\"schema\",\"schoolName\"]],\"'s Logo\"],null],\"sm\",[24,[\"schema\",\"schoolLogoUrl\"]],[28,\"concat\",[[24,[\"schema\",\"schoolName\"]],\"'s Logo\"],null]]]],[0,\"\\n    \"],[6,[23,5,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,6,[\"headline\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[24,[\"schema\",\"schoolName\"]],false],[0,\" \"],[1,[24,[\"schema\",\"enrolmentYear\"]],false],[0,\" Enrolment\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[6]}],[0,\"\\n  \"]],\"parameters\":[5]}],[0,\"\\n\"]],\"parameters\":[4]}],[0,\"\\n\\n\"],[5,\"paper-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"headline\"]],[],[[\"@class\"],[\"text-center\"]],{\"statements\":[[0,\"\\n        Enrolment application successfully submitted!\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n  \"],[6,[23,1,[\"actions\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"paper-button\",[],[[\"@onClick\",\"@primary\"],[[28,\"action\",[[23,0,[]],\"routeNewApplication\",[24,[\"schema\"]]],null],true]],{\"statements\":[[0,\"\\n      New Application\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-enrolments/templates/apply-complete.hbs"
    }
  });
  _exports.default = _default;
});