define("linced-enrolments/models/enrolments/application", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "@linc-technologies/linced-common/mixins/dynamic-fields"], function (_exports, _model, _attributes, _dynamicFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend(_dynamicFields.default, {
    // schoolId filters Enrolment Applications based on the school's LINC-ED Tenant ID.
    // For example, "61f78993-90a9-4392-92dd-83a4368528b8".
    schoolId: (0, _model.attr)('string'),
    // campusId filters Enrolment Applications based on the school's LINC-ED Campus ID.
    campusId: (0, _model.attr)('string'),
    // countryCode is a ISO Alpha 2 country code as to which country the school resides.
    countryCode: (0, _model.attr)('string'),
    // schoolCode is a school identifier as given by a state based educational body.
    schoolCode: (0, _model.attr)('string'),
    // enrolmentYear is the year in which the application was handed in for.
    enrolmentYear: (0, _model.attr)('number'),
    // jurisdiction is the unique ID of the applicable educational jurisdictional authority.
    jurisdiction: (0, _model.attr)('number'),
    // authorId contains the id of the person who authored the received enrolment application.
    authorId: (0, _model.attr)('string'),
    // personId is the linked LINC-ED person account, once the student has been enrolled.
    personId: (0, _model.attr)('string'),
    // contacts is the list of contacts (primary + emergency) associated with this application to enrol.
    contacts: (0, _attributes.fragmentArray)('enrolments/contact'),
    // medicalContacts is the list of doctors/specialists associated with this application to enrol.
    medicalContacts: (0, _attributes.fragmentArray)('enrolments/contact'),
    // medicalConditions is the list of medical conditions associated with this application to enrol.
    medicalConditions: (0, _attributes.fragmentArray)('enrolments/medical-condition')
  });
  _exports.default = _default;
});