define("linced-enrolments/components/paper-form-with-errors", ["exports", "ember-paper/components/paper-form"], function (_exports, _paperForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _paperForm.default.extend({
    validationErrorMessages: Ember.computed('childComponents.@each.isInvalid', function () {
      var errors = Ember.A();
      this.childComponents.forEach(function (field) {
        // Autocomplete fields show up twice (element + trigger input).
        // The autocomplete itself has a null `element`, so we can identify and skip it.
        if (field.element !== null && field.get('isInvalid')) {
          errors.pushObject({
            field: field,
            errors: field.get('validationErrorMessages')
          });
        }
      });
      return errors;
    })
  });
  _exports.default = _default;
});