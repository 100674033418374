define("linced-enrolments/models/posts/attendancenote", ["exports", "@linc-technologies/linced-common/models/posts/attendancenote"], function (_exports, _attendancenote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _attendancenote.default;
    }
  });
});