define("linced-enrolments/models/media/video", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    streamSource: (0, _model.attr)('number'),
    streamId: (0, _model.attr)('string'),
    streamProcessed: (0, _model.attr)('boolean'),
    poster: (0, _model.attr)('string')
  });
  _exports.default = _default;
});