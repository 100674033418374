define("linced-enrolments/models/people-schema/field", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "@linc-technologies/linced-common/utils/strings"], function (_exports, _model, _attributes, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    schoolId: (0, _model.belongsTo)('school'),
    school: Ember.computed.alias('schoolId'),
    sequence: (0, _model.attr)('number'),
    isLocked: (0, _model.attr)('boolean'),
    isVisibleToParents: (0, _model.attr)('boolean'),
    isSiblingLinked: (0, _model.attr)('boolean'),
    personType: (0, _model.attr)('number'),
    key: (0, _model.attr)('string'),
    inputType: (0, _model.attr)('number'),
    dataType: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    default: (0, _attributes.fragment)('dynamic-field/value'),
    placeholder: (0, _model.attr)('string'),
    isRequired: (0, _model.attr)('boolean'),
    options: (0, _attributes.fragmentArray)('people-schema/field-option'),
    optionsFromEnum: (0, _model.attr)('boolean'),
    htmlAttributes: (0, _model.attr)(),
    groupId: (0, _model.belongsTo)('people-schema/group', {
      inverse: 'fields'
    }),
    group: Ember.computed.alias('groupId'),
    templateId: (0, _model.attr)('string'),
    // camelCaseLabel is used to help match fields in bulk imported data.
    camelCaseLabel: Ember.computed('label', function () {
      return (0, _strings.toCamelCase)(this.label.toLowerCase());
    }),
    // requiredRole specifies the Field Access role required to view this field,
    // if applicable.  If empty, anyone with access to the entity can see it.
    requiredRole: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // isReadOnly specifies whether this field is read-only.
    isReadOnly: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });
  _exports.default = _default;
});