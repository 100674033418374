define("linced-enrolments/router", ["exports", "linced-enrolments/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('apply', {
      path: 'apply/:country_code/:school_code'
    });
    this.route('apply-complete', {
      path: 'apply/:country_code/:school_code/complete'
    });
    this.route('school-not-found');

    // Default Route - Do not put routes beneath this one
    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});