define("linced-enrolments/models/enrolments/field", ["exports", "ember-data-model-fragments", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _emberDataModelFragments, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // fieldId is the unique dynamic field identifier.
    fieldId: (0, _model.attr)('string'),
    // index is the relative field position when presented to the user.
    index: (0, _model.attr)('number'),
    // isSiblingLinked, if enabled, updates the field data across known siblings.
    isSiblingLinked: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // key is the unique identifier of the field for the backend.
    key: (0, _model.attr)('string'),
    // inputType informs the frontend how to display the form element.
    inputType: (0, _model.attr)('number'),
    // dataType provides metadata around the specific type the data is.
    dataType: (0, _model.attr)('number'),
    // label is the name of the field that is displayed to the end user.  For example, 'Address 1'
    label: (0, _model.attr)('string'),
    // default is the value the element should be on load.
    default: (0, _attributes.fragment)('dynamic-field/value'),
    // placeholder is the text to show in the input element as an example to the user.
    placeholder: (0, _model.attr)('string'),
    // options provides a way to hold valid options for checkboxes, radio and select dropdowns.
    options: (0, _attributes.fragmentArray)('people-schema/field-option'),
    // optionsFromEnum flags the field as using a front-end enum for the list of potential options.
    optionsFromEnum: (0, _model.attr)('boolean'),
    // htmlAttributes provides a way to dynamically inject HTML attributes on an HTML element.
    htmlAttributes: (0, _model.attr)(),
    // isRequired, if enabled, flags the field as required/mandatory.
    isRequired: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // staticContent enables this field option to contain static content, for example,
    // a description or title relating to the following fields in a list.
    staticContent: (0, _model.attr)('string'),
    // isObsolete, if enabled, flags the field as obsolete
    // (not shown on enrolment form but visible to school)
    isObsolete: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });
  _exports.default = _default;
});