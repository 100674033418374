define("linced-enrolments/controllers/apply", ["exports", "@linc-technologies/linced-common/enums/contact-relationship", "@linc-technologies/linced-common/enums/medical-relationship", "@linc-technologies/linced-common/enums/medical-condition-type", "@linc-technologies/linced-common/enums/input-type"], function (_exports, _contactRelationship, _medicalRelationship, _medicalConditionType, _inputType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    InputType: _inputType.default,
    previewSchemaId: '',
    // From query parameter
    isPreview: Ember.computed('previewSchemaId', 'schema.id', function () {
      return this.previewSchemaId === this.schema.id;
    }),
    showErrors: false,
    relationships: _contactRelationship.default.values,
    medicalRelationships: _medicalRelationship.default.values,
    medicalConditionTypes: _medicalConditionType.default.values,
    allSections: Ember.computed('schema.{sections.[],primaryContacts,emergencyContacts,medicalContacts,medicalConditions}', function () {
      var sections = this.schema.sections.slice();
      sections.pushObjects(Ember.A([this.schema.primaryContacts, this.schema.emergencyContacts, this.schema.medicalContacts, this.schema.medicalConditions]).compact());
      return sections;
    }),
    subtypeField: Ember.computed('schema.medicalConditions.fields.@each.key', function () {
      if (Ember.isEmpty(this.schema.medicalConditions)) {
        return null;
      }
      return this.get('schema.medicalConditions.fields').findBy('key', 'core:subtype');
    }),
    epiPenField: Ember.computed('schema.medicalConditions.fields.@each.key', function () {
      if (Ember.isEmpty(this.schema.medicalConditions)) {
        return null;
      }
      return this.get('schema.medicalConditions.fields').findBy('key', 'core:autoinjectorHeldOnsite');
    }),
    savingApplication: false,
    actions: {
      // getContactField computes whether a contact field is required.
      getContactField: function getContactField(pos, field) {
        if (pos === 1) {
          return field;
        }
        var clone = field.copy();
        clone.set('isRequired', false);
        return clone;
      },
      changeStep: function changeStep(index) {
        this.set('currentStep', index);
        window.location.href = '#steps';
      },
      setContactRelationship: function setContactRelationship(contact, relationship) {
        contact.set('relationship', Ember.isEmpty(relationship) ? null : relationship.value);
      },
      setMedicalRelationship: function setMedicalRelationship(contact, medicalRelationship) {
        contact.set('medicalRelationship', Ember.isEmpty(medicalRelationship) ? null : medicalRelationship.value);
      },
      setMedicalConditionType: function setMedicalConditionType(condition, type) {
        condition.set('type', Ember.isEmpty(type) ? null : type.value);
      },
      submitApplication: function submitApplication() {
        var _this = this;
        if (this.isPreview) {
          // Don't actually allow submission of previews.
          return;
        }
        this.set('savingApplication', true);
        var schema = this.schema;
        // Save the application
        if (!Ember.isEmpty(this.applicationRecord)) {
          this.applicationRecord.save().then(function () {
            // Transition to happy screen.
            _this.send('transitionToComplete', schema);
          }).catch(function (err) {
            //todo: remove schoolId and watch the world burn
            // eslint-disable-next-line no-console
            console.error(err);
          }).finally(function () {
            _this.set('savingApplication', false);
          });
        }
      },
      showErrors: function showErrors() {
        this.set('showErrors', true);
      },
      hideErrors: function hideErrors() {
        this.set('showErrors', false);
      }
    }
  });
  _exports.default = _default;
});