define("linced-enrolments/helpers/field-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function flattenList(values, field) {
    if (Ember.isEmpty(field)) {
      return;
    }
    var options = field.options;
    if (!Ember.isEmpty(field) && !Ember.isEmpty(options)) {
      values = values.map(function (value) {
        var option = options.findBy('value', value);
        if (Ember.isEmpty(option) || Ember.isEmpty(option.label)) {
          return value;
        }
        return option.label;
      });
    }
    return values.toArray().join(', ');
  }
  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      if (params.length < 2 || params.length > 3 || Ember.isEmpty(params[0])) {
        return null;
      }

      // Add an observer which recomputes if the field is flagged as (un)initialised.
      // This allows us to ultimately return a value if the dynamic fields were not
      // yet initialised when this helper was initially called.
      Ember.addObserver(params[0], 'fields._initialised', this, 'recompute');
      var fieldValue = params[0].get('fields.' + params[1]);
      if (Ember.isEmpty(fieldValue)) {
        return;
      }
      switch (fieldValue.datatype) {
        case 2:
          // DataType.LIST = 2
          return flattenList(fieldValue.value, params[2]);
        case 5:
          // DataType.BOOLEAN = 5
          return fieldValue.value ? 'Yes' : 'No';
      }
      if (!Ember.isEmpty(params[2]) && !Ember.isEmpty(params[2].options)) {
        // Field has a list of options.  Find the selected option and return the label.
        var option = params[2].options.findBy('value', fieldValue.value);
        if (!Ember.isEmpty(option)) {
          return option.label;
        }
      }
      return params[0].get('fields.' + params[1] + '.value');
    }
  });
  _exports.default = _default;
});