define("linced-enrolments/models/goals/badge-profile", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // Unique IRI for the Issuer/Profile file.
    issuerId: (0, _model.attr)('string'),
    // Valid JSON-LD representation of the Issuer or Profile type.
    // In most cases, this will simply be the string "Issuer" or the more general
    // "Profile".
    type: (0, _model.attr)('string'),
    // The name of the entity or organization.
    name: (0, _model.attr)('string'),
    // The homepage or social media profile of the entity, whether individual or
    // institutional.
    url: (0, _model.attr)('string'),
    // Contact address for the individual or organization.
    email: (0, _model.attr)('string')
  });
  _exports.default = _default;
});