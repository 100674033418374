define("linced-enrolments/models/people/post-count-base", ["exports", "ember-data-model-fragments", "@ember-data/model", "moment"], function (_exports, _emberDataModelFragments, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    itemId: (0, _model.attr)('string'),
    total: (0, _model.attr)('number'),
    published: (0, _model.attr)('number'),
    latestPostDate: (0, _model.attr)('number'),
    // year is used by some post-count types to denote which year's posts they are counting.
    year: (0, _model.attr)('number'),
    daysSinceLatestPost: Ember.computed('latestPostDate', function () {
      var latest = this.latestPostDate;
      if (latest) {
        return (0, _moment.default)().diff(_moment.default.unix(latest), 'days');
      }
      return Infinity;
    }),
    // isCurrent can be used to help determine whether this count is relevant.
    isCurrent: Ember.computed.and('latestPostDate', 'total')
  });
  _exports.default = _default;
});