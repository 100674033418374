define("linced-enrolments/controllers/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      reload: function reload() {
        location.reload();
      }
    }
  });
  _exports.default = _default;
});