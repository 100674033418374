define("linced-enrolments/controllers/apply-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      // Ask the router to transition us
      routeNewApplication: function routeNewApplication() {
        this.send('transitionToNewApplication', this.schema);
      }
    }
  });
  _exports.default = _default;
});