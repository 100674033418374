define("linced-enrolments/components/dynamic-field", ["exports", "@linc-technologies/linced-common/components/dynamic-field"], function (_exports, _dynamicField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dynamicField.default;
    }
  });
});