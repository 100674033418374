define("linced-enrolments/components/parent-app-home-link", ["exports", "@linc-technologies/linced-common/components/parent-app-home-link"], function (_exports, _parentAppHomeLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _parentAppHomeLink.default;
    }
  });
});