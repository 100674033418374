define("linced-enrolments/components/report/print-subject", ["exports", "@linc-technologies/linced-common/components/report/print-subject"], function (_exports, _printSubject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _printSubject.default;
    }
  });
});