define("linced-enrolments/templates/school-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gNAsCJmR",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\"],\"statements\":[[5,\"paper-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"headline\"]],[],[[],[]],{\"statements\":[[0,\"\\n        404 Not Found\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\\n  \"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Sorry, we couldn't find the school you wanted to enrol in.\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Please check the link in your internet browser's address bar to make sure it matches what the school has provided.\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-enrolments/templates/school-not-found.hbs"
    }
  });
  _exports.default = _default;
});