define("linced-enrolments/models/enrolments/contact", ["exports", "ember-data-model-fragments", "@ember-data/model", "@linc-technologies/linced-common/mixins/dynamic-fields"], function (_exports, _emberDataModelFragments, _model, _dynamicFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend(_dynamicFields.default, {
    type: (0, _model.attr)('number'),
    relationship: (0, _model.attr)('number'),
    medicalRelationship: (0, _model.attr)('number'),
    notes: (0, _model.attr)('string'),
    receivesSms: (0, _model.attr)('boolean'),
    billPayer: (0, _model.attr)('boolean'),
    permissionToContact: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});