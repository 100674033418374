define("linced-enrolments/models/bookings/date", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    // date is a string representation of the event date
    date: (0, _model.attr)('string'),
    // sessions contains one or more sessions for the date.
    sessions: (0, _attributes.fragmentArray)('bookings/session'),
    sessionCount: Ember.computed.alias('sessions.length'),
    flattenedSessions: Ember.computed('sessions.@each.{startTime,endTime}', function () {
      return this.sessions.reduce(function (times, session) {
        times.push(session.startTime);
        times.push(session.endTime);
        return times;
      }, Ember.A()).join();
    })
  });
  _exports.default = _default;
});