define("linced-enrolments/components/mfa/totp-code", ["exports", "@linc-technologies/linced-common/components/mfa/totp-code"], function (_exports, _totpCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _totpCode.default;
    }
  });
});