define("linced-enrolments/models/goals/badge-criteria", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // A narrative of what is needed to earn the badge.
    narrative: (0, _model.attr)('string')
  });
  _exports.default = _default;
});