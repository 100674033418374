define("linced-enrolments/session-stores/application", ["exports", "ember-simple-auth/session-stores/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extends From
  // ApplicationSessionStore is a stub which prevents the session store from
  // linced-common from being initialised.
  var _default = _base.default.extend({
    _name: 'ApplicationSessionStore'
  });
  _exports.default = _default;
});