define("linced-enrolments/models/bookings/session", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    startTime: (0, _model.attr)('number'),
    endTime: (0, _model.attr)('number')
  });
  _exports.default = _default;
});