define("linced-enrolments/helpers/enum-option", ["exports", "@linc-technologies/linced-common/helpers/enum-option"], function (_exports, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _enumOption.default;
    }
  });
  Object.defineProperty(_exports, "enumOption", {
    enumerable: true,
    get: function get() {
      return _enumOption.enumOption;
    }
  });
});