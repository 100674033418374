define("linced-enrolments/routes/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    actions: {
      error: function error(_error, transition) {
        // Inject new relic logging here!
        newrelic.noticeError(_error, {
          transitionFrom: transition.from ? transition.from.name : 'unknown',
          transitionTo: transition.to ? transition.to.name : 'unknown'
        });
        return true;
      }
    }
  });
  _exports.default = _default;
});