define("linced-enrolments/models/goals/badge-assertion", ["exports", "ember-data-model-fragments", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _emberDataModelFragments, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // Relevant JSON-LD context for the Open Badges Specification.
    // https://w3id.org/openbadges/v2
    context: (0, _model.attr)('string'),
    // Unique IRI for the Assertion.
    // If using hosted verification, this should be the URI where the assertion i
    // s accessible.
    // For signed Assertions, it is recommended to use a UUID in the urn:uuid
    // namespace.
    // "https://example.org/assertions/123"
    assertionId: (0, _model.attr)('string'),
    // Valid JSON-LD representation of the Assertion type.
    // In most cases, this will simply be the string "Assertion".
    // "Assertion"
    type: (0, _model.attr)('string'),
    // The recipient of the achievement.
    recipient: (0, _attributes.fragment)('goals/badge-recipient'),
    // Describes the type of badge being awarded.
    badge: (0, _attributes.fragment)('goals/badge-badge'),
    // Instructions for third parties to verify this assertion.
    verification: (0, _attributes.fragment)('goals/badge-verification'),
    // Timestamp of when the achievement was awarded.
    // "2016-12-31T23:59:59+00:00"
    issuedOn: (0, _model.attr)('string')
  });
  _exports.default = _default;
});