define("linced-enrolments/adapters/application", ["exports", "@ember-data/adapter/rest", "linced-enrolments/config/environment"], function (_exports, _rest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _rest.default.extend({
    host: _environment.default.APP.apiHost,
    namespace: _environment.default.APP.apiPath,
    handleResponse: function handleResponse(status, headers, body) {
      if (status === 200 && Object.getOwnPropertyNames(body).length === 0) {
        // We got a 200 but with no results (body is an empty object literal)
        var plural = this.modelNamePlural;
        if (!Ember.isEmpty(plural)) {
          var hash = {};
          hash[this.modelNamePlural] = [];
          return hash;
        }
      }
      return this._super.apply(this, arguments);
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload && _typeof(payload) === 'object' && payload.error && payload.message) {
        return [{
          detail: payload.message
        }];
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});